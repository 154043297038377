// extracted by mini-css-extract-plugin
export var apple = "Pile-module--apple--67e88";
export var block = "Pile-module--block--16a30";
export var connector = "Pile-module--connector--29225";
export var container = "Pile-module--container--1f69a";
export var content = "Pile-module--content--fa47f";
export var des = "Pile-module--des--170db";
export var disabled = "Pile-module--disabled--b0d5a";
export var dot = "Pile-module--dot--5c1a9";
export var download = "Pile-module--download--dc4ad";
export var downloads = "Pile-module--downloads--783b4";
export var header = "Pile-module--header--48064";
export var icon = "Pile-module--icon--a2ef5";
export var introvideo = "Pile-module--introvideo--34966";
export var line = "Pile-module--line--6d7b2";
export var lineHolder = "Pile-module--lineHolder--90408";
export var links = "Pile-module--links--66938";
export var logo = "Pile-module--logo--5e084";
export var meta = "Pile-module--meta--4fb57";
export var name = "Pile-module--name--7d644";
export var space = "Pile-module--space--362f9";
export var tag = "Pile-module--tag--9b76a";
export var version = "Pile-module--version--3245b";
export var wrapper = "Pile-module--wrapper--d5a31";