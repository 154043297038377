import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pile from "../components/projects/Pile"

const PilePage = () => {
  return (
    <Layout wide>
      <SEO
        title="Pile: Reflective journaling app"
        description="Desktop app for reflective journaling, open-source and free."
        category="Software"
        customOGImageUrl={"https://udara.io/portfolio/pile/pile-cover.png"}
      />
      <div className="pile">
        <Pile />
      </div>
    </Layout>
  )
}

export default PilePage
